@font-face {
    font-family: 'Circular Std';
    src: url(/fonts/CircularStd/CircularStd-Book.eot);
    src: url(/fonts/CircularStd/CircularStd-Book.eot?#iefix) format("embedded-opentype"),url(/fonts/CircularStd/CircularStd-Book.woff) format("woff"),url(/fonts/CircularStd/CircularStd-Book.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
/* @font-face {
    font-family: 'Circular Std';
    src: url("../assets/fonts/C_Bold.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'C-Medium';
    src: url("../assets/fonts/C_Medium.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url("../assets/fonts/C_Regular.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
} */

input[type="password"] {
    font: small-caption;
}

@font-face {
    font-family: 'TitleUltraHeavy';
    src: url("../assets/fonts/BloomSpeakTitle-UltraHeavy.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

a:active, a:focus {
    background: transparent !important;
}

.ant-tooltip-inner {
    background: #000 !important;
}

/* .ant-tabs-tab {
    color: rgba(255,255,255,0.6);
} */

.ant-tabs-nav-container {
    background-color: rgb(255 255 255 / 60%);
    padding: 0 10px;
}

.label {
    color: #989998;
    text-align: left;
    text-transform: none;
    font-weight: normal;
}